<template>
  <v-row no-gutters>
    <v-col cols="12">
      <BaseHeadline fake="2" class="mb-4">{{ $t(title) }}</BaseHeadline>
    </v-col>

    <router-view v-if="!$slots.default" />

    <slot />
  </v-row>
</template>

<script>
export default {
  name: "ModuleHome",

  props: {
    title: {
      type: String,
      require: false,
      default: "",
    },

    fullWidth: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
};
</script>
